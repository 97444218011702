<template>
    <div style="margin-top: 10px;">
    <!-- <v-overlay :value="overlay"></v-overlay> -->
    <div class="mainContainers">
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
            <div class="doctorNameAndLogo">
                <div class="doctorProfile">
                    <img :src="item.doctorInfo.doctorLogo" style="border-radius: 50%;"/>
                </div>
                <div class="doctorName">
                    <div style="font-weight: 500; font-size: 14px;">{{ item.doctorInfo.doctorNameEN }}</div>
                    <div style="color: #1467BF; font-weight: 400; font-size: 14px; text-align: left;" class="specialtyBox" v-if="specialtyList">{{ getSpecialtyName(item) }}</div>
                </div>
            </div>
            <div>
                <v-btn 
                    outlined
                    color="#e8e8eb"
                    @click="openDoctorBox"
                    :disabled="viewDoctorLoader"
                    :loading="viewDoctorLoader"

                >
                    <div style="color: #1467BF; font-weight: 400; font-size: 14px;">{{$t("Customer.OpdAppointments.View")}}</div>
                </v-btn>
            </div>
        </div>
        <!-- location and degree -->
        <div class="" style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 16px;">
            <div style="display: flex; gap: 10px; width: 100%; align-items: flex-start; ">
                <img src="https://s3iconimages.mymedicine.com.mm/degree.svg" alt="" style="width: 28px; height: 28px;">
                <div style="display: flex; flex-wrap: wrap; text-align: left;">
                    <div v-for="(degree, index) in limitedAcademicTitles" style="padding-right: 4px; font-size: 14px; font-weight: 400; color: #4f4f4f;" v-if="academicsList[degree]">{{ academicsList[degree].name }}, </div>
                    <div v-if="showViewMore" style="padding-right: 4px; font-size: 14px; color: #1467bf;">
      <a href="#" @click.prevent="openDoctorBox">{{$t("Customer.OpdAppointments.View_more")}}</a>
    </div>
                </div>
            </div>
        </div>
        <!-- location and degree ends -->
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; width: 100%;">
                <div style="color: #828282; font-size: 14px;">{{$t("Customer.OpdAppointments.select_Date")}}</div>
                <div style="display: flex; justify-content: center; align-items: center; gap: 10px;" v-if="showSlot" @click.prevent="toggleSlot">
                    <div style="font-size: 14px; color: #1467bf;">{{$t("Customer.OpdAppointments.Hide_slots")}}</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg" alt="" style="width: 10px; height: 10px; rotate: -90deg;">
                </div>
                <div style="display: flex; justify-content: center; align-items: center; gap: 10px;" v-if="!showSlot" @click.prevent="toggleSlot">
                    <div style="font-size: 14px; color: #1467bf;">{{$t("Customer.OpdAppointments.View_slots")}}</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg" alt="" style="width: 10px; height: 10px; rotate: 90deg;">
                </div>
            </div>
        <!-- select date start -->
        <v-expand-transition>
            <v-card v-show="showSlot" elevation="0" tile width="100%">
        <div style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">

            
            <div v-if="slotLoader" style="width: 95%; display: flex; gap: 8px; justify-content: space-between;">
                <v-skeleton-loader  
                    type="chip"
                ></v-skeleton-loader>
                <v-skeleton-loader  
                    type="chip"
                ></v-skeleton-loader><v-skeleton-loader  
                    type="chip"
                ></v-skeleton-loader>
            </div>
            <div style="width: 100%;" v-if="showSlot">

                <div v-if="noSlot" style="color: gray;">{{$t("Customer.OpdAppointments.No_Slots_available")}}</div>
                <div 
                    class="dateContainer"
                    v-else
                    >
                        <div :class="getClassForDateBox(slotItem.slotID, slotItem)" v-for="(slotItem) in slotInfo" :key="slotItem.slotID" @click="showSelectSlotFn(slotItem.slotID, slotItem)">
                            <div v-if="slotItem.slotID == 'CALENDAR'" style="display: flex; align-items: center; justify-content: center;">
                                <img :src="selectedID == 'CALENDAR' ? 'https://s3iconimages.mymedicine.com.mm/calendarBlue.svg': 'https://s3iconimages.mymedicine.com.mm/calendarGrey.svg'" alt="" style="width: 30px; height: 30px;">
                            </div>
                            <div>
                                <div  class="weekDayClass" style="color: #828282; font-size: 14px;">{{ slotItem.dayValue }}</div>
                                <div class="weekDayClass" style="font-weight: 600; font-size: 14px;">{{ slotItem.dateValue }}</div>
                            </div>    
                        </div>
                </div>
            </div>
        </div>
        <!-- select date end -->
        <!-- select slot start -->
        <div v-if="showSlot && showSelectSlot" style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">
            <div style="color: #828282; font-size: 14px;">{{$t("Customer.OpdAppointments.Select_Slot")}}</div>
            <div style="width: 100%;">
                    <div v-for="(value, key) in selectedDateItem.hospitalList" :key="key" style="margin-bottom: 10px;">
                        <div class="hospitalNameContainer">
                            <div class="hospitalLogo">
                                <img :src="selectedDateItem.hospitalList[key].hospitalInfo.hospitalLogo" style="border-radius: 8px; max-width: 100%; max-height: 100%; object-fit: contain; "/>
                            </div>
                            <div class="hospitalName">
                                <div style="font-size: 16px; font-weight: 600; color: #333333; text-align: left;"> {{ selectedDateItem.hospitalList[key].hospitalInfo.hospitalName }}</div>
                                <div style="font-size: 14px; font-weight: 500; color: #1467BF; text-align: left;">{{ selectedDateItem.hospitalList[key].hospitalInfo.address }}</div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; width: 100%; flex-wrap: wrap; gap: 8px;">
                            <div :class="getClassForSlotBox(timeID.id)" v-for="(timeID) in selectedDateItem.hospitalList[key].slotTimes" :key="timeID.id" @click="showAddPatientFn(timeID.id, timeID, key)">
                                <div class="weekDayClass">{{ timeID.timeInfo }}</div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <!-- select patient start -->
        <div v-if="showSlot && showAddPatient" style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">
            <div style="color: #828282; font-size: 14px;">{{$t("Customer.OpdAppointments.Who_is_patient")}}?</div>
            <div style=" gap: 8px; width: 100%; display: flex;  align-items: center; " class="prajers">
                <v-select
                @input="enableStateList"
                :items="dependent_members"
                outlined
                style="height: 40px;"
                dense
                item-value="_id"
                item-text="dependent_name"
                v-model="dependentSelected"
                >
                <template v-slot:selection="data">
                        <span style="padding-left: 10px;">{{ data.item.dependent_name }}</span>
                    </template></v-select>
                <v-btn depressed color="#e4f5ff" height="40" min-width="40" @click="addDependentModel = true">
                    <img src="https://s3iconimages.mymedicine.com.mm/pluseIcon.svg"  alt="">
                </v-btn>
            </div>
        </div>
        <div v-if="showSlot && showAddPatient && showStateList" style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">
            <div style="color: #828282; font-size: 14px;">{{$t("Customer.OpdAppointments.State_Township")}}</div>
            <div style=" gap: 8px; width: 100%; display: flex;  align-items: flex-start;">
                <div class="inputBox">
                        <v-autocomplete
                            @input="stateSelectionChange"
                            :items="stateList"
                            :dense="true"
                            placeholder="Select state"
                            label=""
                            item-text="name"
                            item-value="id"
                            outlined
                            v-model="selectedState"
                        >
                        <template v-slot:selection="data">
                        <span style="padding-left: 10px;">{{ data.item.name }}</span>
                    </template>
                    </v-autocomplete>
                </div>
            </div>
        </div>
    </v-card>
</v-expand-transition>
        <div>
        <v-bottom-sheet  :persistent="true" v-model="openCalender" max-width="425px" >
            <v-sheet
            class="text-center"
            height="450px"
            >
            <div class="viewDetailsBottomContainer">
                <div>
                    <div class="viewHospitalDetailsBottomSheetHeading">
                        <div style="font-size: 14px; font-weight: 500; color: #4f4f4f;">{{$t("Customer.OpdAppointments.select_Date")}}</div>
                        <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="() => {openCalender = false;}" style="cursor: pointer; width: 25px;"/>
                    </div>
                </div>
                <div class="viewDetailsBody">
                    <v-date-picker v-model="datePickerModel" :no-title="true" :full-width="true" :allowed-dates="isDateAllowed"></v-date-picker>
                </div>
                <div class="bottomBar">
                    <div>{{$t("Customer.OpdAppointments.Clear_All")}}</div>
                    <v-btn color="#48acef" depressed style="color: white; background-color: #48acef;" @click="getParticularDateData" :disabled="calendarLoader || datePickerModel == null" :loading="calendarLoader" >{{$t("Login.GetDetails.Submit")}}</v-btn>
                </div>
                
            </div>

        </v-sheet>
        </v-bottom-sheet>
        </div>
        <div>
            <v-bottom-sheet  :persistent="true" v-model="reviewDialg" max-width="425px"  v-if="bookingAllowed">
                <v-sheet
                class="text-center"
                height="450px"
                >
                <div class="viewDetailsBottomContainer">
                    <div>
                        <div class="viewHospitalDetailsBottomSheetHeading">
                            <div style="font-size: 14px; font-weight: 500; color: #4f4f4f;">{{$t("Customer.OpdAppointments.Review_Details")}}</div>
                            <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="() => {reviewDialg = false;}" style="cursor: pointer; width: 25px;"/>
                        </div>
                    </div>
                    <div class="viewDetailsBody">
                        <doctor-info-component :bookingInfo="bookingInfo"/>
                    </div>
                    <div class="bottomBar">
                        <v-btn depressed color="#48acef" style="color: white;" width="100%" height="40px" @click="bookSlot" :disabled="bookingLoader" :loading="bookingLoader">
                            <img src="https://s3iconimages.mymedicine.com.mm/whiteCircleTick.svg" alt="" style="width: 25px; height: 25px; margin-right: 10px;">
                            {{$t("Customer.PackageAppointments.Confirm_Book")}}
                        </v-btn>
                    </div>
                    
                </div>

            </v-sheet>
            </v-bottom-sheet>
        </div>
        </div>
        <div class="confirmBookingComponent" v-if="bookingAllowed">
            <v-btn depressed color="#48acef" style="color: white;" width="100%" height="40px" @click="openReviewBox">
                <img src="https://s3iconimages.mymedicine.com.mm/whiteCircleTick.svg" alt="" style="width: 25px; height: 25px; margin-right: 10px;">
                {{$t("Customer.OpdAppointments.Review")}}
            </v-btn>
        </div>
        <div v-if="viewDoctorModel">
            <view-doctor-component @toggleViewDoctorDialog="toggleViewDoctorDialog" :doctorInfo="doctorData" :modelValue="viewDoctorModel"/>
        </div>
        <div v-if="addDependentModel">
            <AddDependentComponent :modelValue="addDependentModel" @addDependentInList="addDependentInList" @toggleAddDependentDialog="toggleAddDependentDialog"/>
        </div>
    </div>

</template>

<script>
import { axios_auth_instance_customer, axios_auth_instance_hba_customer } from '../../../../utils/axios_utils';
import doctorInfoComponent from '../bookingSuccessPage/doctorInfoComponent.vue';
import AddDependentComponent from '../commonAcrossPages/addDependentComponent.vue';
import ViewDoctorComponent from '../commonAcrossPages/viewDoctorComponent.vue';

export default {
  components: { doctorInfoComponent, ViewDoctorComponent, AddDependentComponent },
    name: 'ViewParticularDoctorDocFlow',
    props: ["dependent_members", "item", "isSelectedDoctorBooking"], 
    data: function() {
        return {
            bookingInfo: {},
            bookingAllowed: false,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            bookingLoader: false,
            doctorData: null,
            showSelectSlot: false,
            selectedID: -1,
            showAddPatient: false,
            selectedSlotId: -1,
            reviewDialg: false,
            items: ["My Self", "Ankur"],
            currentCustomer: null,
            selectedDateItem: null,
            openCalender: false,
            datePickerModel: null,
            dependentSelected: null,
            showSlot: false,
            slotInfo: [],
            slotLoader: false,
            overlay: false,
            calendarLoader: false,
            allowedDates: [],
            timeInfo: '',
            viewDoctorModel: false,
            selectedHospital: null,
            calendarNumberDate: null,
            addDependentModel: false,
            selectedState: null,
            showStateList: false,
            viewDoctorLoader: false,
            noSlot: false,
            // specialtyList: []
            // dependent_members: []
        };
    },
    async mounted() {
        this.allowedDates = this.getXDaysFromToday(90);
        // alert(this.isSelectedDoctorBooking);
        this.updateShowSlot();

    },
    watch: {
    isSelectedDoctorBooking() {
      this.updateShowSlot();
    }
  },
    computed: {
        hospitalData () {
            return this.$store.state.particularHospitalState.hospitalInfo;        
        },
        stateList () {
            return this.$store.state.locationState.showLocationList;
        },
        specialtyList () {
            return this.$store.state.OPDConfigCommonState.opdSpec;        
        },
        academicsList () {
            return this.$store.state.OPDConfigCommonState.academics;        
        },
        limitedAcademicTitles() {
      return this.item.doctorInfo.academicTitle.slice(0, 2);
    },
    showViewMore() {
      return this.item.doctorInfo.academicTitle.length > 2;
    },
    },
    methods: {
        updateShowSlot() {
      if (this.isSelectedDoctorBooking !== this.item.doctorInfo.id) {
        this.showSlot = false;
            this.resetAllVariables()
      } else {
        this.showSlot = true;
      }
    },
        resetAllVariables() {
            this.selectedID = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            this.timeInfo = null;
            this.dependentSelected = null;
            this.bookingAllowed = false;
            this.selectedState = null;
        },
        getSpecialtyName(item) {
            let findItem = this.specialtyList.find((x) => (x.id == item.doctorInfo.doctorSpecialty));
            console.log(findItem, item, this.specialtyList);
            if (findItem) {
                return findItem.name;
            } else {
                return ''
            }
        },
        calculateDateTime(hours, dateNumber, startTime) {
            console.log(hours, dateNumber, startTime);
            hours = Number(hours);
            const today = new Date();
            today.setHours(today.getHours() + hours);
            const yyyy = today.getFullYear();
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            const dd = String(today.getDate()).padStart(2, '0');
            const HH = String(today.getHours()).padStart(2, '0');
            const mmFormat = String(today.getMinutes()).padStart(2, '0');
            const dateObject = {
                dateValue: Number(`${yyyy}${mm}${dd}`),
                time: `${HH}:${mmFormat}`
            };

            // Check if dateNumber and startTime are greater than dateObject values
            const inputDateValue = Number(dateNumber);
            const inputTimeValue = Number(startTime.replace(':', ''));
            let allowed = inputDateValue > dateObject.dateValue ||
                    (inputDateValue === dateObject.dateValue && inputTimeValue > Number(dateObject.time.replace(':', '')));
            return allowed;
        },
        async openDoctorBox() {
            this.viewDoctorLoader = true;

            try {
                if (this.doctorData == null) {
                    let apiResponse = await axios_auth_instance_hba_customer(`/opd/doctorInfo/${this.item.doctorInfo.id}`);
                    this.doctorData = apiResponse.data.doctorDetails;
                }
                this.viewDoctorModel = true;
                this.viewDoctorLoader = false;

            } catch (error) {
                console.log(error);
            }
        },
        addDependentInList(item) {
            this.dependent_members.push(item);
            this.dependentSelected = item._id;
            this.enableStateList();
            // this.showStateList = true;
            // this.selectedState = item.stateID;
        },
        toggleAddDependentDialog(value) {
            if(value) {
                this.addDependentModel = true;
            } else {
                this.addDependentModel = false;
            }
        },
        toggleViewDoctorDialog(value) {
            if(value) {
                this.viewDoctorModel = true;
            } else {
                this.viewDoctorModel = false;
            }
        },
        formatDate(dateString) {
        // Extract year, month, and day from the given string
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6) - 1; // Months are zero-based in JavaScript
        const day = dateString.substring(6, 8);

        // Create a new Date object
        const date = new Date(year, month, day);

        // Get the day and month name
        const dayName = date.toLocaleString('en-US', { weekday: 'long' });
        const monthName = date.toLocaleString('en-US', { month: 'long' });

        // Format the date
        const formattedDate = `${day} ${monthName}`;

        return formattedDate;
        },

    openReviewBox() {
        
        this.bookingInfo = {
            'doctorInfo': {
                doctorName: this.item.doctorInfo.doctorNameEN,
                doctorLogoURL: this.item.doctorInfo.doctorLogo,
                doctorSpecialty: this.item.doctorInfo.doctorSpecialty,
                hospitalLogo: this.selectedDateItem.hospitalList[this.selectedHospital].hospitalInfo.hospitalLogo,
                hospitalName: this.selectedDateItem.hospitalList[this.selectedHospital].hospitalInfo.hospitalName,
                hospitalAddress: this.selectedDateItem.hospitalList[this.selectedHospital].hospitalInfo.address,
                slotTime: this.timeInfo,
                slotDate: this.selectedID == 'CALENDAR' ? this.formatDate(this.calendarDateNumber) : this.formatDate(this.selectedID)            
            }
        }
        this.reviewDialg = true;
    },
    isDateAllowed(date) {
      return this.allowedDates.includes(date);
    },
    getXDaysFromToday(x) {
        const dates = [];
        const today = new Date();
        for (let i = 0; i < x; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            const yyyy = date.getFullYear();
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            dates.push(`${yyyy}-${mm}-${dd}`);
        }
        return dates;
    },
        async bookSlot() {
            let maxRetries = 3; // Number of retries
            let attempt = 0;
            let success = false;
            this.bookingLoader = true;
            while (attempt < maxRetries && !success) {
                try {
                    let apiResponse = await axios_auth_instance_hba_customer.post('/opd/book', {
                        hospital_ID: this.selectedHospital,
                        slot_ID: `${this.item.doctorInfo.id}#${this.selectedSlotId}`,
                        booked_for: this.dependentSelected,
                        state_ID: String(this.selectedState),
                        speciality: this.getSpecialtyName(this.item)
                    });
                    let bookingID = apiResponse.data.bookingID;
                    success = true;
                    this.$router.push({
                        name: 'hospitalBookingSuccess',
                        params: {
                            bookingID: bookingID,
                        }
                    });
                } catch (error) {
                    attempt++; // Increase attempt count on error
                    if (attempt >= maxRetries) {
                        console.log('Max retries reached:', error);
                    } else {
                        console.log(`Retry attempt ${attempt} failed, retrying...`, error);
                    }                
                } 
            }
            this.bookingLoader = false;
        },
        async toggleSlot() {
            this.$emit('toggleSlot', {
                id: this.item.doctorInfo.id
            });
            this.selectedID = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            if (this.showSlot === false) {
                try {
                    if (this.slotInfo.length != 0) {
                        this.showSlot = true;
                        return;
                    }
                    this.slotLoader = true;
                    let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/slotInfo/${this.item.doctorInfo.id}`);
                    this.slotInfo = apiResponse.data.slotList;
                    // this.slotInfo.push({
                    //         slotID: 'CALENDAR',   
                    //     }
                    // );
                    if (apiResponse.data.noSlot) {
                        this.noSlot = true;
                    } else {
                        this.showSlot = true;
                    }
                } catch (error) {
                    console.log(error);
                    if (error.responseStatus == 401 || error.responseStatus == 403) {
                        localStorage.setItem('redirectionFlow', true);
                        localStorage.setItem('redirectionURL', this.$router.history.current.fullPath);
                        this.$router.push({
                            name: 'Login'
                        });
                    }
                } finally {
                    this.slotLoader = false;
                }
            } else {
                this.showSlot = false;
            }

        },
        enableStateList() {
            // this.bookingAllowed = true;
            this.showStateList = true;
            this.selectedState = null;
            this.bookingAllowed = false;
            let index = this.dependent_members.findIndex((item) => (item._id == this.dependentSelected))
            if(this.dependent_members[index].stateID) {
                this.selectedState = this.dependent_members[index].stateID;
                this.bookingAllowed = true;
            }
        },
        stateSelectionChange() {
            this.bookingAllowed = true;
        },
        // callme() {
        //     this.bookingAllowed = true;
        //     // alert('hi')
        // },
        async getParticularDateData() {
            try {
                this.calendarLoader = true;
                this.overlay = true;
                let splitedDate = this.datePickerModel.split('-');
                let dateInput = splitedDate[0] + splitedDate[1] + splitedDate[2];
                this.calendarNumberDate = dateInput;
                let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/slotInfo/doctor/${this.item.doctorInfo.id}/${dateInput}`);
                if(Object.keys(apiResponse.data.slotList.hospitalList).length == 0) {
                    alert('No slots available');
                    return;
                }
                console.dir(apiResponse.data.slotList);
                this.selectedDateItem = {
                    ...apiResponse.data.slotList,
                    slotID: 'CALENDER',
                }
                this.showSelectSlot = true;
                this.openCalender = false;
            } catch (error) {
                console.log(error);
            } finally {
                this.calendarLoader = false;
            }

        },  
        getClassForDateBox(index, slotItem) {
            // if(slotItem.slotID == 'CALENDAR' && index != this.selectedID) return "dateBox"

            if(slotItem.slotID != 'CALENDAR' && Object.keys(slotItem.hospitalList).length == 0) {
                return "dateBox disabledDateBox"
            }
            if(index == this.selectedID) {
                return "dateBox selectedDateBox"
            } else {
                return "dateBox"
            }
        },
        getClassForSlotBox(index) {
            if(index == this.selectedSlotId) {
                return "slotBox selectedDateBox"
            } else {
                return "slotBox"
            }
        },
        showSelectSlotFn(id, slotItem) {
            this.selectedID = null;
            this.selectedHospital = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            this.bookingAllowed = false;
            this.dependentSelected = null;
            this.selectedState = null;
            // if(id == 'CALENDAR') return;
            if(id == 'CALENDAR') {
                this.selectedID = id;
                this.selectedDateItem = slotItem;
                this.openCalender = true;
                return;
            }
            if(Object.keys(slotItem.hospitalList).length == 0) return;
            this.selectedID = id;
            this.selectedDateItem = slotItem;
            console.log(slotItem);
            this.showSelectSlot = true;
        },
        showAddPatientFn(id, item, hospitalRegNo) {
            if(this.calculateDateTime(this.selectedDateItem.hospitalList[hospitalRegNo].hospitalInfo.cutOffHour, this.selectedDateItem.slotID == 'CALENDER' ? this.calendarNumberDate : this.selectedDateItem.slotID, item.startTime) == false) {
                alert('Booking not allowed');
                return;
            }
            // if any selected before
            this.selectedSlotId = null;
            this.timeInfo = null;
            this.showAddPatient = true;
            this.selectedState = null;
            this.selectedSlotId = id;
            this.timeInfo = item.timeInfo;
            this.selectedHospital = hospitalRegNo;
            this.bookingAllowed = false;
            this.dependentSelected = null;
        },
        goToHospitalPage() {
            this.$router.push({
            name: "ViewHospital",
            });
        },
    },
};

</script>
<style  scoped>
.confirmBookingComponent {
    position: absolute;
    bottom: 0px;
    background: white;
    padding: 14px 16px 12px 16px;
    width: 100%;
    max-width: 425px;
    z-index: 100;
    margin: 0 !important;
}
.mainContainers {
    margin: 0 16px;
    padding: 16px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
}
.headerHeading {
    color: white;
    font-weight: 600;
}
.doctorName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}
.doctorProfile {
    border-radius: 50%;
    max-height: 56px;
    max-width: 56px;
    height: 56px;
    width: 56px;
    border: 1px solid #e0e0e0;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.dateContainer {
    display: grid;
    grid-template-columns: repeat(7, 0.5fr); /* 6 columns with equal width */
    gap: 6px; /* Gap between boxes */
    max-width: 475px;
  }
.doctorNameAndLogo {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
}
.specialtyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4F5FF;
    padding: 3px 8px 3px 8px;
}
.dateBox {
    display: flex;
    /* padding: 4px 12px 4px 12px; */
    border: 1px solid #e0e0e0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    /* width: 43px; */
    /* height: 43px; */
}
.selectedDateBox {
    background: #E4F5FF;
    border: 1px solid #48ACEF !important;
}
.disabledDateBox {
    background: #FFF;
    border: 1px solid #f2f2f2 !important;
}
.selectedDateBox .weekDayClass {
    color: #48ACEF !important;
}
.disabledDateBox .weekDayClass {
    color: #e0e0e0 !important;
    font-weight: 800 !important;
}
.slotBox {
    display: flex;
    align-items: center;
    padding: 10px 12px 10px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #828282;
    font-weight: 400;
    width: 100%;
}
.v-input >>> .v-input__slot {
    padding: 0 !important;
}
.v-input__slot >>> .v-text-field {
    display: none;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.viewHospitalDetailsBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.viewDetailsBottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.viewDetailsBody {
    padding: 20px 16px 20px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}
.bottomBar {
    border-top: 1px solid #e8e8eb;
    display: flex; justify-content: space-between; align-items: center; width: 100%;
    padding: 12px 16px 12px 16px;
    box-shadow: 0px -4px 8px 0px #0000000A;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: absolute;
    bottom: 0;
    max-width: 425px;
}
.hospitalNameContainer {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
}
.hospitalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 56px;
    max-width: 56px;
    min-height: 56px;
    min-width: 56px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.inputBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}
::v-deep .v-input{
    width: 100%;
}
::v-deep .v-text-field__details{
    display: none;
}
::v-deep .v-date-picker-table .v-btn.v-btn--active {
    background-color: #1467BF !important;
    /* color: #fff !important; */
}
</style>